import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";

const Content = styled.div`
  text-align: center;
`;

const MaintenancePage = () => {
  return (
    <Content>
      {/* <h1>The new site is live!</h1> */}
      <h1>Legacy.SmartScaleSync.com is being updated</h1>
      <p>Tuesday, March 19, 2024 11:00:00 PM GMT-07:00</p>
      <p>
        We are in the process of updating the legacy website! We will post
        updates on{" "}
        <a href="https://twitter.com/smartscalesync">our twitter account</a> if
        there are any issues, please expect us to be back online within 24
        hours.
      </p>
      {/* <p>
        If you are seeing this page, your browser is still pointing to the old
        website. Try clearing your cache or browser history and then refreshing
        the page.
      </p> */}
    </Content>
  );
};

export default MaintenancePage;
